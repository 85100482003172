import { defineStore } from 'pinia';
import { useAuthStore } from '~/store/auth';

export const useNavigationStore = defineStore('navigation-store', {
    state: () => {
        return {
            mainMenuOpen: false,
            loadingStateActive: false,
            mijnTln: {},
            primaryMenuItems: [],
            secondaryMenuItems: [],
            primarySignedInMenuItems: [],
            secondarySignedInMenuItems: [],
            activeDropdownTrigger: '',
            signupEntry: null,
            membershipInfoEntry: null,
            communityUrl: null
        };
    },

    getters: {
        primaryMenu() {
            if (useAuthStore().loggedIn) {
                return this.primarySignedInMenuItems;
            }

            return this.primaryMenuItems;
        },
        secondaryMenu() {
            if (useAuthStore().loggedIn) {
                return this.secondarySignedInMenuItems;
            }

            return this.secondaryMenuItems;
        },
        isMainMenuOpen() {
            return this.mainMenuOpen;
        },
        isLoadingStateActive() {
            return this.loadingStateActive;
        },
        activeDropdown() {
            if (!this.activeDropdownTrigger) {
                return null;
            }

            return this.activeDropdownTrigger.getAttribute('data-dropdown');
        }
    },

    actions: {
        // Menu overlay
        toggleMainMenu() {
            this.mainMenuOpen = !this.mainMenuOpen;
        },

        openMainMenu() {
            this.mainMenuOpen = true;
        },

        closeMainMenu() {
            this.mainMenuOpen = false;
        },

        // Global loading state (curtain)
        toggleLoadingState() {
            this.loadingStateActive = !this.loadingStateActive;
        },

        enableLoadingState() {
            this.loadingStateActive = true;
        },

        disableLoadingState() {
            this.loadingStateActive = false;
        },

        closeDropdown() {
            // Check if activeDropdownTrigger is an html element which can be focused.
            if (this.activeDropdownTrigger instanceof HTMLElement) {
                this.activeDropdownTrigger.focus();
            }

            this.activeDropdownTrigger = '';
        },

        openDropdown($event) {
            this.activeDropdownTrigger = $event.srcElement;
        },

        setGlobalData(globalData) {
            if (!globalData) {
                return;
            }

            const generalData = globalData?.general[0];
            const mainNavigation = globalData?.mainNavigation[0];
            const signedInNavigation = globalData?.signedInNavigation[0];
            const mijnTln = globalData?.mijnTln[0];

            // Set global signup page entry
            if (generalData && generalData.signupEntry && generalData.signupEntry.length > 0) {
                this.signupEntry = generalData.signupEntry[0];
            }

            // Set global membership info page entry
            if (generalData && generalData.membershipInfoEntry && generalData.membershipInfoEntry.length > 0) {
                this.membershipInfoEntry = generalData.membershipInfoEntry[0];
            }

            // Set global community URL
            if (generalData && generalData.communityUrl) {
                this.communityUrl = generalData.communityUrl;
            }

            if (mainNavigation) {
                this.primaryMenuItems = mainNavigation.primaryNavigation;
                this.secondaryMenuItems = mainNavigation.secondaryNavigation;
            }

            if (signedInNavigation) {
                this.primarySignedInMenuItems = signedInNavigation.primaryNavigation;
                this.secondarySignedInMenuItems = signedInNavigation.secondaryNavigation;
            }

            if (mijnTln) {
                this.mijnTln = mijnTln;
            }
        },
    }
});
